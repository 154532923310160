<template>
    <div class="item align-items-center">
        <div class="item-num ff-m fw-6 text-white mb-3">{{id}}</div>
        <div class="item-str ff-r fs-m fw-4 text-center">{{string}}</div>
    </div>
</template>

<script>
    export default {
        name: "CardNum",
        props: {
            id: {
                type: Number,
                default: 1,
            },
            string: {
                type: String,
                default: '',
            }
        },
    }
</script>

<style lang="scss" scoped>
    .item {
        display: flex;
        flex-direction: column;
        margin: 0 0 30px 0;
        @include _992 {
            margin: 0 0 40px 0;
        }

        &-num {
            width: 50px;
            height: 50px;
            background: $bblue;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: $fs-h4;
        }
    }
</style>