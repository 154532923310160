<template>
  <section class="container-xxl">
    <div class="sect-base">
      <div class="row justify-content-start">
        <div class="col-12 col-lg-10">
          <h2 class="h2 ff-m fw-6 text-start mb-3 mb-md-4">О фонде</h2>
          <div class="ff-r fs-l fw-4 text-start pb-5">
            Фонд развития правовой культуры и правосознания – некоммерческая организация, реализующая
            проекты, направленные на развитие правовой культуры и правосознание граждан и предпринимателей.
          </div>
        </div>
      </div>
      <fund-banner/>
    </div>
  </section>
  <section class="bg-dagray py-5">
    <div class="container-xxl">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-11">
          <h2 class="h2 ff-m fw-7 text-uppercase text-center text-white mb-5 pb-2">
            История создания и развития фонда
          </h2>
        </div>
      </div>
    </div>
    <swiper class="thumb-swiper"
            :centeredSlides="true"
            :slideToClickedSlide="true"
            @swiper="setGallerySwiper"
            :controller="{ control: thumbSwiper }"
            :breakpoints='{
                                "0": {
                                    "slidesPerView": 1,
                                     "navigation": false,
                                    "pagination": false,
                                },
                                 "568": {
                                    "slidesPerView": 2,
                                    "spaceBetween": 30
                                },
                                "768": {
                                    "slidesPerView": 3,
                                    "spaceBetween": 30
                                },
                                 "1024": {
                                    "slidesPerView": 5,
                                    "spaceBetween": 30
                                },
                                 "1200": {
                                    "slidesPerView": 6.5,
                                    "spaceBetween": 50
                                },
                            }'
    >
      <swiper-slide v-for="(thumbSlide, ts) in history" :key="`thumb-${ts}`">
        <div class="slide-title ff-m text-center">
          {{ thumbSlide.date }}
        </div>
      </swiper-slide>
    </swiper>
    <swiper class="base-swiper"
            :slidesPerView="1"
            :centeredSlides="true"
            :navigation="true"
            :pagination="{ 'dynamicBullets': true }"
            @swiper="setThumbSwiper"
            :controller="{ control: gallerySwiper }"
            :breakpoints='{
                                "0": {
                                    "autoHeight": true,
                                },
                                "1112": {
                                    "autoHeight": false,
                                },
                            }'
    >
      <swiper-slide v-for="(gallerySlide, gs) in history" :key="`gallery-${gs}`">
        <div class="row justify-content-center mt-4 pt-3">
          <div class="col-12 col-lg-10">
            <p class="fs-m fw-5 ff-gr text-white text-center mb-3"
               v-for="(str, strI) in gallerySlide.data" :key="`gallery-str-${strI}`">
              {{ str }}
            </p>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </section>
  <section class="container-xxl">
    <div class="sect-base mt-5">
      <div class="row">
        <div class="col-12 ff-r fs-l">
          <p>В своей деятельности Фонд развития правовой культуры и правосознания выполняет миссию по
            реализации проектов, направленных на формирование высокого уровня правовой культуры населения,
            традиции безусловного уважения к закону, правопорядку и суду, добропорядочности и
            добросовестности как преобладающей модели социального поведения, а также на преодоление
            правового нигилизма в обществе, который препятствует развитию России как современного
            цивилизованного государства.</p>
          <p>
            Деятельность Фонда в областе развития правовой культуры и правосознания направлена на выполнение
            задач, стоящих перед государством, профессиональным сообществом, общественными организациями и
            обществом в целом, изложенных в Основах государственной политики Российской Федерации в сфере
            развития правовой грамотности и правосозрания граждан, утвержденных Президентом РФ 28.04.2011 N
            Пр-1168.
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="container-xxl">
    <div class="sect-base">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-12">
          <h2 class="h2 ff-m fw-6 text-center mb-5 pb-2">
            Цели фонда
          </h2>
        </div>
      </div>
      <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
        <div class="col" v-for="(obj, i) in fundObjectives" :key="`fund-obj-${i}`">
          <card-num :id="obj.num" :string="obj.objective"/>
        </div>
      </div>
    </div>
  </section>
  <section class="container-xxl">
    <div class="sect-base">
      <div class="row justify-content-center">
        <div class="col-12">
          <h2 class="h2 ff-m fw-6 text-center mb-5 pb-2">
            Наша команда
          </h2>
        </div>
        <div class="col-12">
          <swiper class="base-swiper"
                  :slidesPerView="1"
                  :navigation="true"
                  :autoHeight="true"
                  :pagination="{ 'dynamicBullets': true }"
                  :breakpoints='{
                                "0": {
                                    "autoHeight": true,
                                },
                                "1112": {
                                    "autoHeight": false,
                                },
                            }'>
            <swiper-slide v-for="(crewman, i) in team" :key="`crewman-${i}`">
              <div class="row">
                <div class="col-12 col-sm-6 col-lg-4">
                  <img v-if="crewman.src"
                       :src="require(`@/assets/${crewman.src}`)"
                       :alt="crewman.name"
                       class="w-100 mb-4"
                  >
                </div>
                <div class="col-12 col-sm-6 col-lg-7">
                  <h5 class="h5 ff-m fw-6 mb-3 text-center text-sm-start">{{ crewman.name }}</h5>
                  <div class="ff-r fs-m fw-4 mb-4 text-sm-start">{{ crewman.post }}</div>
                  <div class="ff-r fs-b fw-4 mb-3 text-sm-start" v-for="(str, j) in crewman.data"
                       :key="`crewman-str-${j}`"
                       v-html="str"/>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </section>
  <section class="container-xxl">
    <div class="sect-base">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-12">
          <h2 class="h2 ff-m fw-6 text-center mb-5 pb-2">
            Учредительные документы
          </h2>
        </div>
      </div>
      <div class="row justify-content-between">
        <div class="col col-12 col-sm-6 col-lg-6 mb-4">
          <a href="/pdf/main/свидетельство-о-постановке-на-учет.pdf" target="_blank" referrerpolicy="no-referrer"
             class="d-flex flex-row align-items-center link-jump _green mb-3">
            <svg class="icon me-2" viewBox="0 0 45 46">
              <use xlink:href='#icon-doc' x="0" y="0"/>
            </svg>
            Свидетельство о государственной регистрации Фонда развития правовой культуры и правосознания
          </a>
        </div>
        <div class="col col-12 col-sm-6 col-lg-3 mb-4">
          <a href="/pdf/main/лист-записи.pdf" target="_blank" referrerpolicy="no-referrer"
             class="d-flex flex-row align-items-center link-jump _green mb-3">
            <svg class="icon me-2" viewBox="0 0 45 46">
              <use xlink:href='#icon-doc' x="0" y="0"/>
            </svg>
            Лист записи
          </a>
        </div>
        <div class="col col-12 col-sm-6 col-lg-3 mb-4">
          <a href="/pdf/main/устав-фонда.pdf" target="_blank" referrerpolicy="no-referrer"
             class="d-flex flex-row align-items-center link-jump _green mb-3">
            <svg class="icon me-2" viewBox="0 0 45 46">
              <use xlink:href='#icon-doc' x="0" y="0"/>
            </svg>
            Устав Фонда
          </a>
        </div>
        <div class="col col-12 col-sm-6 col-lg-6 mb-4">
          <a href="свидетельство-о-постановке-на-учет.pdf" target="_blank" referrerpolicy="no-referrer"
             class="d-flex flex-row align-items-center link-jump _green mb-3">
            <svg class="icon me-2" viewBox="0 0 45 46">
              <use xlink:href='#icon-doc' x="0" y="0"/>
            </svg>
            Свидетельство о постановке на учет
          </a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import {mapActions, mapState} from 'vuex';

import CardNum from "@/components/AllCards/CardNum";
import FundBanner from "@/components/FundBanner";

import {Swiper, SwiperSlide} from 'swiper/vue';
import SwiperCore, {Controller, Navigation, Pagination} from "swiper";

SwiperCore.use([Controller, Navigation, Pagination]);

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

export default {
  name: 'AboutPage',
  components: {
    CardNum,
    FundBanner,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      thumbSwiper: null,
      gallerySwiper: null,
      fundObjectives: [
        {
          num: 1,
          objective: 'Формирование в обществе устойчивого уважения к закону и преодоление правового нигилизма',
        },
        {
          num: 2,
          objective: 'Повышение уровня правовой культуры граждан, включай уровень осведомленности и юридической грамотности',
        },
        {
          num: 3,
          objective: 'Внедрение в общественное сознание идеи добросовестного исполнения обязанностей и соблюдения правовых норм',
        },
        {
          num: 4,
          objective: 'Улучшение правового климата в стране',
        },
        {
          num: 5,
          objective: 'Улучшение законодательства страны',
        },
        {
          num: 6,
          objective: 'Пропаганда здорового образа жизни',
        },
      ]
    };
  },
  computed: {
    ...mapState({
      history: state => state.history.all,
      team: state => state.team.all,
    })
  },
  methods: {
    setThumbSwiper(swiper) {
      this.thumbSwiper = swiper;
    },
    setGallerySwiper(swiper) {
      this.gallerySwiper = swiper;
    },
    ...mapActions({
      getHistory: 'history/getHistory',
      getTeam: 'team/getTeam',
    }),
  },
  async mounted() {
    await this.getHistory();
    await this.getTeam();
  },
}
</script>
<style lang="scss" scoped>
.thumb-swiper {
  overflow: inherit;

  .swiper-slide {
    padding: 0 0 30px 0;

    .slide-title {
      font-size: 72px;
      line-height: normal;
      font-weight: 600;
      color: #404755;

      transition: all 200ms linear;
      transform: scale(0.65);
      position: relative;
    }

    &:before {
      content: '';
      height: 1px;
      width: calc(100% + 50px);
      background: #404755;
      position: absolute;
      right: -50px;
      bottom: 0;
      z-index: 1;
    }

    &:after {
      content: '';
      width: 14px;
      height: 14px;
      background: #404755;
      border-radius: 50%;
      position: absolute;
      left: calc(50% - 7px);
      bottom: -7px;
      z-index: 5;
    }

    &:first-child:before {
      width: 80%;
    }

    &:last-child:before {
      width: 100%;
      right: auto;
      left: -50%;
    }

    &-active {
      .slide-title {
        font-weight: 700;
        color: #FFFFFF;
        transform: scale(1);
        position: relative;
      }

      &:before {
        background: linear-gradient(90deg, #404755 0%, #0076DE 50%, rgba(64, 71, 85, 0) 100%, #404755 100%);
        z-index: 5;
        width: 200%;
        right: -50%;
      }

      &:after {
        content: '';
        width: 20px;
        height: 20px;
        background: $bblue;
        left: calc(50% - 10px);
        bottom: -10px;
        z-index: 4;
      }

      &:first-child:before {
        width: 200%;
        right: -150%;
        background: linear-gradient(90deg, #0076DE 0%, #404755 100%);
        z-index: 3;
      }

      &:last-child:before {
        background: linear-gradient(90deg, #404755 0%, #0076DE 100%);
        z-index: 3;
      }
    }
  }
}
</style>